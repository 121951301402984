<template>
<div>
  <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
  <CRow class="mt-4">
    <CCol sm="6">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="formatedItemsTpCargo"
        :fields="fieldsTpCargo"
        :noItemsView="tableText.noItemsViewText"
        hover
        small
        sorter
        pagination
        :items-per-page="5"
      >
      <template #TpCargoId="{ index}">
        <td>
          {{index+1}}
        </td>
      </template>
      <template #Status="{ item }">
        <td class="text-center">
          <CSwitch
            color="success"
            variant="opposite"
            :checked.sync="item.Status"
            @update:checked="addRemoveId($event, item)"
          >
          </CSwitch>
        </td>
      </template>
      </dataTableExtended>
      <p v-if="this.arrIds.length == 0" class="mt-2 text-danger">
        * {{$t('label.must')+$t('label.toSelect')+$t('label.atLeast')}} 1 {{$t('label.container')}}
      </p>
    </CCol>
    <CCol sm="6">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="formatedItemsTpCargoDetailAsociacion"
        :fields="fieldsAsociados"
        pagination
        :noItemsView="tableText.noItemsViewText"
        sorter
        small
        :items-per-page="5"
      >
      <template #TpCargoId="{index}">
        <td>
          {{index+1}}
        </td>
      </template>
      </dataTableExtended>
    </CCol>
  </CRow>
  <CRow>
    <CCol sm="12" class="text-right">
      <CButton 
        color="add" 
        class="first"
        @click="sendData()"
      >
        <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
      </CButton>
    </CCol>
  </CRow>
</div>
</template>

<script>
import General from "@/_mixins/general";
import { mapState } from "vuex";

//DATA
function data() {
  return {
    items: [],
    arrIds: [],
    Loading:  false,
    TpCargoJson:[],
    TpCargoYardId: '',
    TpCargoId: '',
    Status: 0,
    GpoCargoId: '17cc5c90-ab86-4ce2-bdba-91210574dab7'
  };
}

//METHOD
function getListContainer() {
  this.Loading = true;
  this.$http
  .get('TpCargoYardAll-list',{ YardId: this.getYardId, GpoCargoId: this.GpoCargoId})
  .then(response => {
    let listado = response.data.data;

    if(listado && listado.length != 0){
      this.arrIds = listado.filter((item) => item.FgActCargoYard)
      this.TpCargoJson = listado;
    }else{
      this.TpCargoJson = [];
      this.arrIds = [];
    }

  })        
  .finally(() => {
    this.Loading = false;
  });
}

function getYards() {
  this.Loading = true;
  this.$http
    .get('Yard-list',{ CompanyBranchId: this.getBranchId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        let YardData = listado.find(item => item.YardId == this.yardSelected.YardId);
        this.$store.state.yard.yardSelected = YardData;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function addRemoveId(event, item) {
  if (event) {
    this.arrIds.push({...item})
  } else {
    this.arrIds = this.arrIds.filter((e) => e.TpCargoId != item.TpCargoId)
  }
}

function sendData(){
  try {
    this.Loading = true;
    let TpCargoYardJson = [];

    if (this.arrIds.length == 0) {
      this.Loading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    this.TpCargoJson.map((e) => {
        let dataEdit =  this.arrIds.some((item) =>{ return item.TpCargoId === e.TpCargoId });

        if(e.TpCargoYardId || dataEdit) 
          TpCargoYardJson.push(
            {
              YardId: this.getYardId,
              TpCargoYardId: e.TpCargoYardId ?? '',
              TpCargoId: e.TpCargoId,
              Status: dataEdit ? 1 : 0,
            }
          );
    })

    this.$http.post("TpCargoYard-insert", TpCargoYardJson, { root: 'TpCargoYardJson' })
      .then((response) => {
        this.$notify({
          group: 'container',
          title: '¡'+this.$t('label.success')+'!',
          text: response.data.data[0].Response,
          type: 'success',
        });
        this.cleanDataSelect();
        this.getListContainer();
        this.getYards();

        this.Loading = false;
        
      })
      .catch((err) => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: 'error',
        });
        this.Loading = false;
      });
  } catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
  
}

function cleanDataSelect(){
  this.arrIds = [];
  this.TpCargoJson = [];
}

// COMPUTED
function fieldsTpCargo() {
  return [
    { key: 'TpCargoId', label: '#',_style: 'width:1%; text-align:left',filter: false},
    { key: 'TpCargoNameEs',label: this.$t('label.group')+' '+('(ES)'), _style:'min-width:100px;', _classes:'text-center' },
    { key: 'TpCargoNameEn',label: this.$t('label.group')+' '+('(EN)'), _style:'min-width:100px;', _classes:'text-center' },
    { key: 'TpCargoCode',label: this.$t('label.acronym'), _style:'width:20%;', _classes:'text-center'},
    { key: 'Status',label:'',sorter: false,_style:'width:3%;',},
  ]
}
function fieldsAsociados() {
  return [
    { key: 'TpCargoId', label: '#',filter: false,_style: 'width:1%; text-align:center;', },
    { key: 'TpCargoNameEs',label: this.$t('label.associatedEquipment')+' '+('(ES)'), _style:'min-width:100px;', _classes:'text-center' },
    { key: 'TpCargoNameEn',label: this.$t('label.associatedEquipment')+' '+('(EN)'), _style:'min-width:100px;', _classes:'text-center' },
  ]
}

function formatedItemsTpCargo() {
  return  this.TpCargoJson.map((item) => Object.assign({}, item, {
      ...item,
      Status: (item.Status == "ACTIVO" || item.Status == 1) ?  true : false},
    ));
}
function formatedItemsTpCargoDetailAsociacion() {
  let data = [];

  this.arrIds.map((item) => {
    item.Status && data.push(item)
  });

  return  data;
}
function getYardId() {
  return this.yardSelected.YardId ? this.yardSelected.YardId : "";
}

function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}

export default {
  name: "container",
  mixins: [General],
  props:{Tab: Number},
  data,
  methods: {
    getListContainer,
    getYards,
    sendData,
    addRemoveId,
    cleanDataSelect,
  },
  computed: {
    fieldsTpCargo,
    fieldsAsociados,
    formatedItemsTpCargo,
    formatedItemsTpCargoDetailAsociacion,
    getYardId,
    getBranchId,
    ...mapState({
      branch: (state) => state.auth.branch,
      yardSelected: state => state.yard.yardSelected,
      tabIndex: state => state.yard.tabIndex,
    }),
  },
  watch: {
    tabIndex:function (val){
    if(val == 1){
      this.getListContainer();
      this.cleanDataSelect();
    }
    },
    Tab:function (val){
      if(val == 0){
        this.getListContainer();
        this.cleanDataSelect();
      }
    }
  },
};
</script>